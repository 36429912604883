import Header from '../../Header'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import * as React from 'react'
import { Helmet } from 'react-helmet'

const Doggos = (props) => {
  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, user-scalable=no' />
        <script
          type='text/javascript'
          src='//tag.getdrip.com/1952934.js'
          async
        />
      </Helmet>
      {/* Header */}
      <Header />

      {/* Site / Body */}

      <div className={style.body}>
        <div className={style.content}>{props.children}</div>

        {/* Footer */}
        <div className={style.footerContainer}>
          <div className={style.footerContent}>
            <div className={style.text}>© doggos</div>
            <div className={style.linkContainer}>
              {/* <Link className={style.sitterLink} to='/add-business'>
                Become a sitter
              </Link> */}

              <Link to='/terms'>Terms</Link>
              <Link to='/privacy'>Privacy</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Doggos
