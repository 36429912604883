import * as React from 'react'
import Media from 'react-media'
import Doggos from './Doggos/index'
import './global.css'

function Template(props) {
  return (
    <Media query="(min-width: 460px)">
      {(matchesPhone) => (
        <Media query="(min-width: 767px)">
          {(matchesTablet) => (
            <>{process.env.GATSBY_SITE === 'doggos' && <Doggos {...props} />}</>
          )}
        </Media>
      )}
    </Media>
  )
}

export default Template
