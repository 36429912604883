// extracted by mini-css-extract-plugin
export var body = "style-module--body--33e57";
export var bounce = "style-module--bounce--4ae29";
export var content = "style-module--content--f09e0";
export var dot = "style-module--dot--58985";
export var footerContainer = "style-module--footerContainer--4d09a";
export var footerContent = "style-module--footerContent--33d42";
export var headerContainer = "style-module--headerContainer--f4dcc";
export var headerContent = "style-module--headerContent--87ca3";
export var linkContainer = "style-module--linkContainer--d0a37";
export var logo = "style-module--logo--70775";
export var sitterLink = "style-module--sitterLink--9a384";
export var text = "style-module--text--1ee32";
export var userName = "style-module--userName--3d08b";